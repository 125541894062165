<template>
    <div>
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>流量组</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/customerCard' }">
                    客户名片
                </el-breadcrumb-item>
                <el-breadcrumb-item>编辑</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="detail-info">
            <el-form ref="form" v-loading="formLoading" :model="form" :rules="rules" label-width="140px" size="mini">
                <el-form-item class="input" label="昵称： " label-position="left" prop="nickname">
                    <el-input v-model="form.nickname" style="width: 200px"></el-input>
                </el-form-item>

                <el-form-item class="input" label="号码类型：" label-position="left" prop="contact_type">
                    <el-radio v-model="form.contact_type" :label="1">微信号</el-radio>
                    <el-radio v-model="form.contact_type" :label="2">手机号</el-radio>
                </el-form-item>

                <el-form-item class="input" label="微信号/手机号： " label-position="left" prop="contact_number">
                    <el-input v-model="form.contact_number" style="width: 200px"></el-input>
                </el-form-item>

                <el-form-item class="input" label="来源： " label-position="left" prop="source">
                    <el-select v-model="form.source" class="search-select" clearable placeholder="状态" size="mini">
                        <el-option :value="1" label="小红书"> </el-option>
                        <el-option :value="2" label="抖音"> </el-option>
                        <el-option :value="3" label="头条"> </el-option>
                        <el-option :value="4" label="百度"> </el-option>
                        <el-option :value="5" label="微博"> </el-option>
                        <el-option :value="6" label="其他"> </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="满意度： " label-position="left">
                    <el-rate v-model="form.intention"></el-rate>
                </el-form-item>

                <el-form-item class="input" label="跟进状态： " label-position="left" prop="followed_status">
                    <el-select v-model="form.followed_status" class="search-select" clearable placeholder="状态" size="mini">
                        <el-option :value="0" label="未跟进"> </el-option>
                        <el-option :value="1" label="已跟进"> </el-option>
                    </el-select>
                </el-form-item>



                <el-form-item>
                    <el-button :loading="loading" size="small" type="primary" @click="onSubmit">
                        保存
                    </el-button>
                    <el-button size="small" style="margin-left: 10px" @click="$router.back()">
                        取消
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    name: "Add",

    data() {
        return {
            loading: false,
            form: {},
            formLoading: false,
            rules: {
                nickname: [{ required: true, trigger: "blur", message: "请输入名称" }],
                contact_type: [
                    { required: true, trigger: "change", message: "请选择号码类型" },
                ],
                contact_number: [
                    { required: true, trigger: "blur", message: "请输入微信号/手机号" },
                ],
                source: [
                    { required: true, trigger: "change", message: "请选择来源" },
                ],
                status: [
                    { required: true, trigger: "change", message: "请选择状态" },
                ],
            },
        };
    },
    methods: {
        ...mapActions("customerCard", ["getCustomer", "editCustomer"]),
        onSubmit() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.addClass();
                }
            });
        },
        async addClass() {
            this.loading = true;
            const { res_info } = await this.editCustomer(this.form);
            this.loading = false;
            this.$router.back();
            if (res_info != "ok") return;
            this.$message.success("编辑成功");
        },
        async init() {
            this.formLoading = true;
            const { data } = await this.getCustomer({ id: this.$route.params.id });
            this.form = data;
            this.formLoading = false;
        },
    },
    mounted() {
        this.init();
    },
};
</script>
